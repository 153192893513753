import {Location} from 'Common/config/PageConfigTypes';
import {Id} from 'Common/Id';
import { SetStoreFunction} from 'solid-js/store';
import {JSX,children,Show,createSignal} from 'solid-js';
import { css } from '@emotion/css';
import { palette } from 'Shared/backend/Theme';

/*
	A standard wrapper for inputs.
	For problem input types consider creating special wrappers, e.g. <AdjacentWrap>, <UploaderWrap>, etc.
	For custom jobs perhaps don't use it at all.

	Wrappers are often layed out using flex. In this case its probably simpler to use gap than margins.
*/


const fieldWrapStyle = () => css({
	width: '100%',
});

export const labelStyle = () => css({
	margin: '6px 0',
	fontWeight: 'bold',
	fontSize: 12,
	whiteSpace: 'nowrap',
	color: '#666',
	fontFamily: 'inherit',
	cursor: 'pointer',

	padding: 0  //TODO remove/look at again after removing Less
});

const disableStyle = () => css({
	color: 'grey'
});

export const alertStyle = () => css({
	color: 'red'
});

const errorStyle = () => css({
	color: 'red'
});

const instructionsAfterLabelStyle = () => css({
	lineHeight: '1.2em',
	margin: '2px 0px',
	fontSize: '0.8em',
	color: '#4d4d4d',  //TODO dont think I should have to have this
});	


export const noteStyle = () => css({
	fontSize: '0.8em',
	lineHeight: '1.2em',
	margin: '2px 0px',
	color: palette.label  //XXX do we need this?
});

const labelOnLeftStyle = () => css({
	display: 'flex',
	gap: '0.5em'
});

const labelOnRightStyle = () => css({
	display: 'flex',
	gap: '0.3em'
});


export interface IField<D> {
	page: XXX;
	permission: string;
	docId: Id,
	location: Location,
	field:string;
	ref?: HTMLDivElement;
	processInput?: (value:string) => string;
	store: D;
	setStore: SetStoreFunction<D>;
}


interface IWrap {
	label:string|JSX.Element;
//	children: InputType;
	children: any;
	required?:boolean;
	id?: string;
	disable?:boolean;
	alert?:boolean;
	instructions?:string;
	instructionsAfterLabel?:string;
	notes?:string;
	error?:string;
	/* Is the label to the left of the value? Otherwise its above */
}

export function Wrap(props:IWrap)
{
	const c = children(() => props.children);
	const childId = (c() as HTMLElement)?.id; 

	const [error,setError] = createSignal(undefined);

	const checkError = (e:Event) =>  setError(e.target!.dataset?.error);  //TODO remove this dataset stuff

	return (
		<div classList={
			{[fieldWrapStyle()]:true, 
			[disableStyle()]:props.disable}
		}
			onInput={(e:Event) => checkError(e)}
			onChange={(e:Event) => checkError(e)}
		>
			<Show when={props.instructions}>
				<div class={noteStyle()}>{props.instructions}</div> 
			</Show>

			<label for={childId} classList={{
				[labelStyle()]:true, 
				[disableStyle()]:props.disable,
				[alertStyle()]:props.alert
			}}>
				{props.label}
				<Show when={props.required}>&#42;</Show>
			</label>

			<Show when={props.instructionsAfterLabel}>
				<div class={instructionsAfterLabelStyle()}>{props.instructionsAfterLabel}</div>
			</Show>

			<Show when={error()}>
				<div class={errorStyle()}>
					{ capitalize(error()!)}
				</div>
			</Show>

			{c()}

			<Show when={props.notes}>
				<div class={noteStyle()}>{props.notes}</div>
			</Show>
		</div>
	);
}	

export function LabelOnLeftWrap(props:IWrap)
{
	const c = children(() => props.children);
	const childId = (c() as HTMLElement)?.id; 

	return (
		<div classList={
			{[fieldWrapStyle()]:true, 
			[disableStyle()]:props.disable}
		}>
			<Show when={props.instructions}>
				<div class={noteStyle()}>{props.instructions}</div> 
			</Show>

			<div class={labelOnLeftStyle()}> 
				<label for={childId} classList={{
					[labelStyle()]:true, 
					[disableStyle()]:props.disable,
					[alertStyle()]:props.alert
				}}>
					{props.label}
					<Show when={props.required}>&#42;</Show>
				</label>

				{c()}
			</div>

			<Show when={props.notes}>
				<div class={noteStyle()}>{props.notes}</div>
			</Show>

			<Show when={props.error}>
				<div class={errorStyle()}>
					{ capitalize(props.error)}
				</div>
			</Show>
		</div>
	);
}

export function LabelOnRightWrap(props:IWrap)
{
	const c = children(() => props.children);
	const childId = (c() as HTMLElement)?.id; 

	return (
		<div classList={
			{[fieldWrapStyle()]:true, 
			[disableStyle()]:props.disable}
		}>
			<Show when={props.instructions}>
				<div class={noteStyle()}>{props.instructions}</div> 
			</Show>

			<div class={labelOnRightStyle()}> 
				{c()}

				<label for={childId} classList={{
					[labelStyle()]:true, 
					[disableStyle()]:props.disable,
					[alertStyle()]:props.alert
				}}>
					{props.label}
					<Show when={props.required}>&#42;</Show>
				</label>
			</div>

			<Show when={props.notes}>
				<div class={noteStyle()}>{props.notes}</div>
			</Show>

			<Show when={props.error}>
				<div class={errorStyle()}>
					{ capitalize(props.error)}
				</div>
			</Show>
		</div>
	);
}

/* --- Private: --- */

function capitalize(s:string)
{
	return s.charAt(0).toUpperCase() + s.slice(1);
}

