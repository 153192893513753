import { css, injectGlobal } from '@emotion/css'
import { BackendWrap } from 'Shared/backend/BackendWrap';
import { Breadcrumbs } from 'Shared/backend/Breadcrumbs';
import { headingWrapStyle } from 'Shared/backend/eventsPage/EventsPage';
import { Widget } from 'Shared/backend/widgets/Widget';
import { WidgetTitle } from 'Shared/backend/widgets/WidgetTitle';
import { IconColour } from 'Shared/backend/IconColour';
import { LinkOpen } from 'Shared/backend/icons/LinkOpen';
import { Theme } from 'Shared/backend/Theme'
import { theme, ThemeProvider } from 'Shared/backend/ThemeProvider';
import { standardRules } from 'Shared/common/Sanitise'
import { addItemAndOpen, StoreRepeater } from 'Shared/forms/StoreRepeater';
import { PageProvider } from 'Shared/frontend/PageProvider';
import { mergeProps } from 'solid-js';
import { createStore } from 'solid-js/store';
import { HomeSelector } from 'Shared/backend/homePage/HomeSelector';


//TODO ben open showcasefields as an overlay like event > lineup artist forms, CJ couldn't get working 

const sectionStyle = () => css({
	marginBottom: '2em'
});


export function HomePage(props)
{
	injectGlobal([standardRules,{body:Theme.body}]);

	let itemsNode;

	const [home,setHome] = createStore(props.home);

	const homeProps:IHomeProps = mergeProps({
		page: props.page,
		venue: props.venue,
		docId:home._id,
		store:home,
		setStore:setHome,
		location:[],
		permission:'edit'
	});


	return (
		<ThemeProvider theme={Theme}>
			<PageProvider page={props.page}>
				<BackendWrap>
					<Breadcrumbs items={{
						Admin: '/admin',
						Homepage: null
					}} />

					<div class={headingWrapStyle()}>
						<h2 class={css(theme().heading)}>Home Page</h2>
						<a href='/' target='_blank'>
							<LinkOpen class={css(Theme.linkIcon)} fill={IconColour.text} />
						</a>
					</div>

					<section class={sectionStyle()}>
						<h3 class={css(theme().section.blueHeading)}>Create and order page sections</h3>

						<StoreRepeater ref={itemsNode} {...homeProps} page={props.page} field='showcase'
							renderTitle={i => <WidgetTitle {...i()} /> }
						>
							{(item,index) => <Widget {...homeProps} {...item} selector={HomeSelector} location={['showcase',index]} index={index} />}
						</StoreRepeater>

						<button class={css(theme().button)}
							onClick={() => addItemAndOpen({...homeProps,permission:'edit',field:'showcase'},itemsNode,{})}
						>
							<i class='fas fa-plus'/> Add Section
						</button>
					</section>
				</BackendWrap>
			</PageProvider>
		</ThemeProvider>
	);
}
