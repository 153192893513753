import { z } from 'zod';
import * as t from 'Shared/model/basic';
import * as imageSize from 'Shared/model/ImageSizes';
import {venueSettingsType} from 'Shared/view/VenueSettingsType';


export const emails = z.object({
	primary: z.boolean(),
	type: z.enum(['custom','reservations','hire','perform','work','custom']),
	customLabel: z.string(),
	email: z.string()
});

export const operatingHour = z.object({
	daysopen: z.array(z.enum(['mon','tue','wed','thu','fri','sat','sun'])),
	opens: t.time,
	closes: t.time,
	customLabel: z.string()
});

export const otherSocialMedia = z.object({
	title: z.string(),
	handle: z.string(),
	url: t.url
});

export const thirdPartyService = z.object({
	title: z.enum(['reservations','accommodation','uberEats','menulog','deliveroo','doordash','other']),
	url: t.url
});

export const aboutList = z.object({
	display: z.array(z.enum(['home','contact','reservation','hire','members','menu','events','staff','other'])),
	title: z.string(),
	description: z.string()
});

//arranges display and order of Food Courses on Frontend
//todo ben move from venue.ts to appropriate location
export const showcaseCoursesFood = z.object({
	course: z.string(),
	teaser: z.string(),
	title: z.string(),
	tagline: z.string(),
	details: z.string(),
	addLink: z.boolean(),
	link: t.url,
	linkText: z.string(),
});
//arranges display and order of Drinks Courses on Frontend
//todo ben move from venue.ts to appropriate location
export const showcaseCoursesDrinks = z.object({
	course: z.string(),
	teaser: z.string(),
	title: z.string(),
	tagline: z.string(),
	details: z.string(),
	addLink: z.boolean(),
	link: t.url,
	linkText: z.string(),
});




export const venueDoc = z.object({
	_id: t.id,
	siteId: t.id,
	name: z.string().max(100),
	nickname: z.string().max(100),
	streetAddress: z.string().max(100),
	suburb: z.string().max(50),
	postcode: z.string().max(10),
	state: z.string().max(50),
	country: z.string().max(50),
	mapUrl: t.url,
	mapEmbed: z.string().max(2000),
	postalAddress: z.string().max(500),
	phoneNumber: z.string().max(50),
	abn: z.string().max(20),
	acn: z.string().max(20),
	about: z.string().max(2000),
	logo: t.image,
	logoLandscape: t.image,
	photo: t.image,
	facebook: t.facebook,
	instagram: t.instagram,
	twitter: t.twitter,
	snapchat: z.string().max(50),
	tiktok: z.string().max(50),
	email: z.array(emails),
	operatingHours: z.array(operatingHour),
	otherSocialMedia: z.array(otherSocialMedia),
	thirdPartyServices: z.array(thirdPartyService),
	aboutList: z.array(aboutList),
	//showcase: z.array(showcase),
	//showcaseCourses: z.array(showcaseCourses),
	showcaseCoursesFood: z.array(showcaseCoursesFood),
	showcaseCoursesDrinks: z.array(showcaseCoursesDrinks),
	settings: venueSettingsType
})
.strict()
.partial().required({
	_id:true,
	siteId:true,
	enabled:true,
	email:true,
	operatingHours:true,
	otherSocialMedia:true,
	thirdPartyServices:true,
	aboutList:true,
	//showcase: true,
	//showcaseCourses: true,
	showcaseCoursesFood: true,
	showcaseCoursesDrinks: true,
	
	settings:true
});

export type VenueDoc = z.infer<typeof venueDoc>;


export class VenueLinks
{
	schema = venueDoc;

	images = {
		logo: imageSize.venueLogo,
		photo: imageSize.venuePhoto
	}
}
