import { inverseHeadingStyle } from 'Shared/backend/homePage/HomeSelector';
import { SingleSelect } from 'Shared/forms/Inputs';

export function HireSelector(props)
{
	return (
		<div class={inverseHeadingStyle()}>
			<label>Layout:</label>

			<SingleSelect {...props} field='layout' required={true} placeholder='SELECT A SECTION LAYOUT' options={{
				buttons: 'Button Row',
				displayTOC: 'Display Table of Contents',
				noImage:'Info with no image',
				slideshow:'Image(s) with optional info overlayed',
				ss_imageRight:'Image(s) to right of Info on widescreens',
				ss_imageLeft:'Image(s) to left of Info on widescreens',
				ss_imageAbove:'Heading(s), Image(s) then Details',
				ss_imageBelow:'Image(s) then Info'
			}} />
		</div>
	);
}

