import { css, cx } from '@emotion/css';
import { CarouselWidget } from 'Shared/backend/widgets/CarouselWidget';
import { ParallaxWidget } from 'Shared/backend/widgets/ParallaxWidget';
import { theme } from 'Shared/backend/ThemeProvider';
import { Checkbox, Text } from 'Shared/forms/Inputs'
import { LabelOnLeftWrap, LabelOnRightWrap, Wrap } from 'Shared/forms/Wrap'
import { usePage } from 'Shared/frontend/PageProvider';
import { Show } from 'solid-js'
import { Match, Switch } from 'solid-js'
import { WidgetInfo } from 'Shared/backend/widgets/WidgetInfo';
import { ImagesRepeater } from 'Shared/backend/widgets/ImagesRepeater';
import { Dynamic } from 'solid-js/web';

export const paneStyle = () => css({
	padding: '2em 1em',
	border: '1px solid black',
	marginTop: '0.5em'
});

export const verticalStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	gap: '0.8em',
//	marginTop: '1em'
});

const verticalWithTopMarginStyle = () => css(verticalStyle(),{
	marginTop: '1em'
});

const headingStyle = () => css(theme().section.heading, {
	fontSize: '1.2em'
});


export function Widget(props)
{
	const page = usePage();
	const layout = () => props.layout;

	return (
		<div class={cx(paneStyle(),verticalStyle())}>
			{/* Different pages use different widgets, so use Dynamic component */}
			<Dynamic component={props.selector} {...props} />

			<Switch>
				<Match when={layout() == 'ss_imageAbove' && !props.sameInfo}>
					<i>&nbsp; Tip: using the same info for all images works best for this layout</i>
				</Match>
				<Match when={layout() == "buttons"}>
					<LabelOnRightWrap label='Sticky'>
						<Checkbox {...props} field='stickyButtons' />
					</LabelOnRightWrap>
				</Match>
			</Switch>

			<Switch>
				<Match when={layout() == 'noImage'}>
					<WidgetInfo {...props} />
				</Match>
				<Match when={layout() == 'buttons'}>
					<ImagesRepeater {...props} />
				</Match>
				<Match when={layout() == 'venuePhotoAndLogo'}>
					<a href='/admin/contact/about'>Images are taken from your standard venue info <u>here</u></a>
				</Match>
				<Match when={layout()=='slideshow' || layout()=='ss_imageRight' || 
					layout()=='ss_imageLeft' || layout()=='ss_imageBelow' || layout()=='ss_imageAbove'}
				>
					<div class={verticalStyle()}>
						<SectionInfoOptional {...props} />
						<h4 class={css(theme().section.heading)}>{props.sameInfo ? 'Images' : 'Content Blocks'}</h4>
						<ImagesRepeater {...props} />
					</div>
				</Match>
				<Match when={layout()?.includes('carousel')}>
					<CarouselWidget {...props} />
				</Match>
				<Match when={layout() == 'parallax'}>
					<ParallaxWidget {...props} />
				</Match>
				<Match when={layout() == 'popup'}>
					<Popup {...props} />
				</Match>
			</Switch>

			<br/>

			<Show when={layout()}>
				<a target='_blank' href={(page.name()=='backend/home' ? '/' : '/hire')  + '#widget'+props.index}>
					<button class={css(theme().smallButton)}>View on website</button>
				</a>
			</Show>
		</div>
	);
}

function SectionInfoOptional(props)
{
	return (
		<div>
			<Show when={props.layout == 'slideshow'}>
				<LabelOnLeftWrap label='Make extra wide on big screens' notes={`Good to tick if it is the first section 
					on your page. It will be wider than other sections on big screens, but will fill to the edges and 
					crop the image heights to look good.`}
				>
					<Checkbox {...props} field='noSectionWidth' />
				</LabelOnLeftWrap>
			</Show>

			<LabelOnLeftWrap label={<h4 class={headingStyle()}>Use the same Info for all Images?</h4>}>
				<Checkbox {...props} field='sameInfo' />
			</LabelOnLeftWrap>

			<Show when={props.sameInfo}>
				<WidgetInfo {...props} />
			</Show>
		</div>
	);
}

function Popup(props)
{
	return (
		<div class={verticalWithTopMarginStyle()}>
			<Wrap label='Embed iframe url'>
				<Text {...props} field='embedUrl'/>
			</Wrap>

			<Wrap label='Embed width'>
				<Text {...props} field='embedWidth' placeholder='500px'/>
			</Wrap>

			<Wrap label='Embed height'
				notes='Tip: check how it looks on mobile or a narrow screen, and if a scrollbar appears increase the height'
			>
				<Text {...props} field='embedHeight' placeholder='500px'/>
			</Wrap>
		</div>
	);
}


