import { Match } from 'solid-js';
import { Show } from 'solid-js';
import { Switch } from 'solid-js';
import { For } from 'solid-js';

export function WidgetTitle(props)
{
	const layoutMap = {
		venuePhotoAndLogo: 'Venue Photo with Logo Overlayed',
		displayTOC: 'Table of Contents',
		buttons: 'Button Row',
		noImage:'Info with no image',
		slideshow:'Images with info overlayed',
		ss_imageRight:'Images right of info',
		ss_imageLeft:'Images left of info',
		ss_imageAbove:'Same headings, images then details',
		ss_imageBelow:'Images then same info',
		carouselAllEvents:'Carousel of all events',
		carouselMusic:'Carousel of Music events',
		carouselNonMusic:'Carousel of Non-music events',
		carouselSpecials:'Carousel of All Specials',
		carouselSpecials_group1:'Carousel of Specials Group 1',
		carouselSpecials_group2:'Carousel of Specials Group 2',
		carouselSpecials_group3:'Carousel of Specials Group 3',
		gallery:'Gallery: Images with same info',
		parallax: 'Parallax image',
		popup: 'Popup'
	};

	return (<>
		<Switch>
			<Match when={props.layout=='displayTOC'}></Match>
			<Match when={props.layout=='buttons'}>
				&nbsp;
				<For each={props.items}>{item => <>
					<Show when={item.linkText}>
						{item.linkText}
					</Show>

					<Show when={props.items.length > 1}>
						{' • '}
					</Show>
				</>}</For>
			</Match>
			<Match when={true}>
				<Switch>
					<Match when={props.title && (props.sameInfo || props.layout=='noImage')}>
						<Show when={props.capacity}>
							{props.capacity},
						</Show>
						<Show when={props.area}>
							{props.area}:{' '}
						</Show>

						{props.title}
					</Match>
					<Match when={props.layout?.includes('carouselSpecials')}>
						{props.title}
					</Match>
					<Match when={props.teaser && (props.sameInfo || props.layout=='noImage')}>
						 {props.teaser}
					</Match>
					<Match when={props.tagline && (props.sameInfo || props.layout=='noImage')}>
						{props.tagline}
					</Match>
					<Match when={true}>
						<For each={props.items}>{item => <>
							{item.title ?? item.imageCaption}

							<Show when={props.items.length > 1}>
								{' • '}
							</Show>
						</>}</For>
					</Match>
				</Switch>
			</Match>
		</Switch>

		{' '}

		<Switch>
			<Match when={props.layout}>
				<i>{layoutMap[props.layout]}</i>
			</Match>
			<Match when={true}>
				<inline-content class='alert'>Set a layout</inline-content>
			</Match>
		</Switch>
	</>);
}


