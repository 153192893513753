import { css } from '@emotion/css';
import { VenueUrls } from 'Common/VenueUrls';
import { locateSubDocument } from 'Common/ViewUtils';
import { AddButton } from 'Shared/backend/widgets/AddButton';
import { indentPaneStyle } from 'Shared/backend/widgets/CarouselWidget';
import { ImageUpload } from 'Shared/forms/ImageUpload';
import { Checkbox, Html, Text } from 'Shared/forms/Inputs';
import { LabelOnRightWrap, Wrap } from 'Shared/forms/Wrap';
import { usePage } from 'Shared/frontend/PageProvider';
import { Match, Show,Switch } from 'solid-js';
import { reconcile } from 'solid-js/store';

export const paneStyle = () => css({
	border: '1px solid black',
	display: 'flex',
	flexDirection: 'column',
	gap: '0.8em',
	padding: '2em 1em',
	marginTop: '0.3em'
});

const imageUploadStyle = () => css({
	img: {
		maxWidth: 450,
		maxHeight: 300
	}
});


export function ImagesWidget(props)
{
	const page = usePage();
	const urls = new VenueUrls(page.build,page.site.key);

	const item = () => locateSubDocument(props.store,props.location);

	const setImage = value => props.setStore(...props.location,'image',reconcile(value));
	const image = () => locateSubDocument(props.store,[...props.location,'image']);

	return (
		<div class={paneStyle()}>
			<Switch>
				<Match when={props.layout=='buttons'}>
					<AddButton {...props} />
				</Match>
				<Match when={true}>
					<Wrap label='Teaser'>
						<Text {...props} field='teaser' />
					</Wrap>

					<Wrap label='Title'>
						<Text {...props} field='title' />
					</Wrap>

					<Wrap label='Tagline'>
						<Text {...props} field='tagline' />
					</Wrap>

					<Wrap label='Details'>
						<Html {...props} urls={urls} field='details' options={{
							height: 200,
							menubar: false,
							plugins: ['link'],
							toolbar: 'removeformat | undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | link ',
							statusbar: true,
							resize: true
						}} />
					</Wrap>

					<LabelOnRightWrap label='Add Button'>
						<Checkbox {...props} field='addLink' />
					</LabelOnRightWrap>

					<Show when={item().addLink}>
						<div class={indentPaneStyle()}>
							<AddButton {...props} />
						</div>
					</Show>

					<Wrap label='Image (landscape ratio 3:2)'>
						<div class={imageUploadStyle()}>
							<ImageUpload 
								docId={props.docId}
								small={true}
								location={[...props.location,'image']} 
								image={image()} 
								setImage={setImage} 
								urls={new VenueUrls(page.build,page.site.key)}
								dimensions='landscape'
								permission='carouselImage'
								fallbackUrl='defaults/profile3x2.png'
								previewSize='full'
								page={page}
							/>
						</div>
					</Wrap>

					<Wrap label='Image Caption' notes='Displayed on image'>
						<Text {...props} field='imageCaption' />
					</Wrap>
				</Match>
			</Switch>
		</div>
	);
}

