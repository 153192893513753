import { css, injectGlobal } from '@emotion/css';
import { BackendWrap } from 'Shared/backend/BackendWrap';
import { IconColour } from 'Shared/backend/IconColour';
import { LinkOpen } from 'Shared/backend/icons/LinkOpen';
import { Music } from 'Shared/backend/icons/Music';
import { Social } from 'Shared/backend/icons/Social';
import { Tv } from 'Shared/backend/icons/Tv';
import { palette, Theme } from 'Shared/backend/Theme';
import { theme, ThemeProvider } from 'Shared/backend/ThemeProvider';
import { EventTimeNotes, EventTimeSummary } from 'Shared/backend/EventTimeSummary';
import { standardRules } from 'Shared/common/Sanitise';
import { PageProvider, usePage } from 'Shared/frontend/PageProvider';  //TODO maybe move in common
import { For, Match, Show, Switch } from 'solid-js';
import { artistNames } from 'Shared/frontend/eventPage/ArtistNames';
import { StatusIcons } from 'Shared/backend/eventPage/StatusIcons';
import { PageFunctions } from 'Browser/PageFunctions';
import { VenueUrls } from 'Common/VenueUrls';
import { Breadcrumbs } from 'Shared/backend/Breadcrumbs';
import { CreateMessage2 } from 'Common/Messages';
import { Event } from 'Shared/view/backend/Event';
import { DateTime } from 'luxon';


const sectionStyle = () => css({
	margin: '1em 0 3em 0'
});

const tableStyle = () => css({
	display: 'grid',
	gridTemplateColumns: '1fr auto 2fr 220px auto',
	columnGap: '1em',
	rowGap: '0.5em',
	alignItems: 'center',
	width: '100%',
	overflowX: 'auto'
});

const tableHeaderStyle = () => css({
	gridColumn: 'span 5',
	display: 'grid',
    gridTemplateColumns: 'subgrid',
	background: '#1b2126',
	color: 'white',
	padding: '1em 0.5em',
	textTransform: 'uppercase',
	fontWeight: 600

/*
	Not using sticky headers here as they don't work with overflow-x:auto which is required
	to work on narrow screen. The columns here are all self explanatory anyway.
	COULD re-enable just for wide screens I guess.
*/
//	position: 'sticky',
//	top: 70,
//	zIndex: 1,
});

const headingBarStyle = () => css({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

//TODO extract out this shared bits
export const headingWrapStyle = () => css({
	display: 'flex',
	alignItems: 'center',
	gap: '0.5em',
});

const posterColumnStyle = () => css({
	justifySelf: 'center',
	textAlign: 'center'
});

const statusColumnStyle = () => css(theme().a, {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center'
});

const posterErrorStyle = () => css({
	color: palette.error
});

const notesStyle = () => css({
	color: '#666',
	fontSize: '0.8em'
});

const dateStyle = () => css({
	color: palette.darkText
});

const nameStyle = () => css({
	color: palette.darkText
});

export function EventsPage(props)
{
	injectGlobal([standardRules,{body:Theme.body}]);

	return (
		<ThemeProvider theme={Theme}>
			<PageProvider page={props.page}>
				<BackendWrap>
					<Breadcrumbs items={{
						Admin: '/admin',
						Events: null
					}} />

					<CurrentEvents {...props} />

					<PastEvents {...props} />
				</BackendWrap>
			</PageProvider>
		</ThemeProvider>
	);
}

function CurrentEvents(props)
{
	const page = usePage();

	return (
		<section class={sectionStyle()}>
			<header class={headingBarStyle()}>
				<div class={headingWrapStyle()}>
					<h2 class={css(theme().heading)}>Current Events</h2>
					<a href='/events' target='_blank'>
						<LinkOpen class={css(theme().linkIcon)} fill={IconColour.text} />
					</a>
				</div>

				<button class={css(theme().button)} onClick={() => createEvent(page)}>Create event</button>
			</header> 
			
			<div class={tableStyle()}>
				<div class={tableHeaderStyle()}>
					<div>Date</div>
					<div />
					<div>Event Name</div>
					<div class={posterColumnStyle()}>Poster</div>
					<div>Published</div>
				</div>
		
				<For each={props.events}>{event =>
					<CurrentEvent {...event} />
				}</For>
			</div>

			<button class={css(theme().button)} onClick={() => createEvent(page)}>Create event</button>
		</section>
	);
}

function CurrentEvent(props)
{
	return (
		<>
			<DateEntry {...props} />

			<EventTypeIcon {...props} />

			<EventName {...props} />

			<EventImage {...props} />

			<StatusIcon {...props} />
		</> 
	);
}

function PastEvents(props)
{
	return (
		<section class={sectionStyle()}>
			<div class={tableStyle()}>
				<div class={tableHeaderStyle()}>
					<div>Finished Events</div>
					<div />
					<div>Name</div>
					<div style={posterColumnStyle()}>Poster</div>
					<div>Status</div>
				</div>

				<For each={props.pastEvents}>{event =>
					<PastEvent {...event} />
				}</For>
			</div>
		</section>
	);
}

function PastEvent(props)
{
	return (
		<>
			{/* keeping a hrefs wrapped around each area so we can customise their link locations in the future */}

			<DateEntry {...props} />

			<EventTypeIcon {...props} />

			<EventName {...props} />

			<EventImage {...props} />
						
			<StatusIcon {...props} />
		</>    
	);
}

function EventTypeIcon(props)
{
	return (
		<div>
			<Switch>
				<Match when={props.eventType=='music'}>
					<Music class={css(theme().linkIcon)} fill={IconColour.inactive} />
				</Match>
				<Match when={props.eventType=='social'}>
					<Social class={css(theme().linkIcon)} fill={IconColour.inactive} />
				</Match>
				<Match when={props.eventType=='tv'}>
					<Tv class={css(theme().linkIcon)} fill={IconColour.inactive} />
				</Match>
			</Switch>
		</div>
	);
}

function StatusIcon(props)
{
	return (
		<a class={statusColumnStyle()} href={`admin/event/${props.slug}/details`}>
			<StatusIcons {...props} />

			<div class={notesStyle()}>
				{props.slug}
			</div>
		</a>         
	);
}

function DateEntry(props)
{
	/* Keeping a hrefs wrapped around each area so we can customise their link locations in the future */

	return (
		<a class={dateStyle()} href={`/admin/event/${props.slug}/details`}>
			<Show when={!props.times.startSingle && !props.times.startTime && !props.times.allDay}>
				<div class={dateStyle()}>
					Add date
				</div>
			</Show>
		
			<EventTimeSummary {...props.times} />
			<EventTimeNotes {...props.times} />
		</a>
	);
}


function EventName(props)
{
	return (
		<a class={nameStyle()} href={`/admin/event/${props.slug}/details`}>
			<Switch>
				<Match when={props.name}>
					{ props.name }
				
					<Show when={props.lineup?.length > 0}>
						{' '}
						<div class={notesStyle()}>
							{artistNames(props.lineup)}
						</div>
					</Show>
				</Match>
				<Match when={props.lineup?.length > 0}>
					{artistNames(props.lineup)}
				</Match>
				<Match when={true}>
					<div class={posterErrorStyle()}>
						Add name (or delete event if not needed)
					</div>
				</Match>
			</Switch>
		</a>
	);
}
						
function EventImage(props)
{
	return (
		<a class={posterColumnStyle()} href={`/admin/event/${props.slug}/details`}>
			<Switch>
				<Match when={props.poster?.imageExists}>
					<Show when={props.poster.isCurrent === false}>
						<div class={posterErrorStyle()}>
							Info has changed, update poster on Event details tab
						</div>
					</Show>
				</Match>
				<Match when={!props.poster?.formats?.tiny}>
					<div class={posterErrorStyle()}>
						No poster set
					</div>
				</Match>
				<Match when={true}>
					<Show when={props.poster?.formats?.tiny}>
						<img loading='lazy' src={posterUrl(props.poster)}
							width={props.poster.formats.tiny.width}
							height={props.poster.formats.tiny.height}
						/>
					</Show>
				</Match>
			</Switch>
		</a>
	);
}

function posterUrl(poster)
{
	if (!poster?.hash) return '';
	return (new PageFunctions()).createImageUrl2(
		new VenueUrls(usePage().build,usePage().site.key),poster.hash,poster,'Event-poster','tiny');
}


async function createEvent(page)
{
//XXX add a random bit on this too
	const key = Math.floor(((new Date()).getTime()-1697150000000)/1000).toString(36).toUpperCase();

	const initial = {
		slug:key,
		status:'draft',
		imageType:'auto',
		times:{
			frequency:'once',
			startSingle: DateTime.now().startOf('day').plus({day:1}).toISO({includeOffset:false,suppressMilliseconds:true})
		},
		imageDimensions:'portrait4x5'
	};

	await page.server.sendOperation(new CreateMessage2(Event.pageName,'edit',initial));
	window.pageJs(`/admin/event/${key}`);
}


