import { css, injectGlobal } from '@emotion/css';
import { Breadcrumbs } from 'Shared/backend/Breadcrumbs';
import { BackendWrap } from 'Shared/backend/BackendWrap';
import { sectionStyle } from 'Shared/backend/eventPage/artist/ArtistForm';
import { headingWrapStyle } from 'Shared/backend/eventsPage/EventsPage';
import { WidgetTitle } from 'Shared/backend/widgets/WidgetTitle';
import { Widget } from 'Shared/backend/widgets/Widget';
import { IconColour } from 'Shared/backend/IconColour';
import { LinkOpen } from 'Shared/backend/icons/LinkOpen';
import { Theme } from 'Shared/backend/Theme';
import { theme, ThemeProvider } from 'Shared/backend/ThemeProvider';
import { standardRules } from 'Shared/common/Sanitise';
import { addItemAndOpen, StoreRepeater } from 'Shared/forms/StoreRepeater';
import { PageProvider } from 'Shared/frontend/PageProvider';
import { mergeProps } from 'solid-js';
import { createStore } from 'solid-js/store';
import { HireSelector } from 'Shared/backend/hirePage/HireSelector';

export function HirePage(props)
{
	injectGlobal([standardRules,{body:Theme.body}]);

	let itemsNode;

	const [hire,setHire] = createStore(props.hire);

	const hireProps:IHireProps = mergeProps({
		page: props.page,
		venue: props.venue,
		docId:hire._id,
		store:hire,
		setStore:setHire,
		location:[],
		permission:'edit'
	});

	return (
		<ThemeProvider theme={Theme}>
			<PageProvider page={props.page}>
				<BackendWrap>
					<Breadcrumbs items={{
						Admin: '/admin',
						Hire: null
					}} />

					<div class={headingWrapStyle()}>
						<h2 class={css(theme().heading)}>Hire Page</h2>
						<a href='/' target='_blank'>
							<LinkOpen class={css(Theme.linkIcon)} fill={IconColour.text} />
						</a>
					</div>

					<section class={sectionStyle()}>
						<h3 class={css(theme().section.blueHeading)}>Create and order page sections</h3>

						<StoreRepeater ref={itemsNode} {...hireProps} page={props.page} field='showcase'
							renderTitle={i => <WidgetTitle {...i()} /> }
						>
							{(item,index) => <Widget {...hireProps} {...item} selector={HireSelector} location={['showcase',index]} index={index} />}
						</StoreRepeater>

						<button class={css(theme().button)}
							onClick={() => addItemAndOpen({...hireProps,permission:'edit',field:'showcase'},itemsNode,{})}
						>
							<i class='fas fa-plus'/> Add Section
						</button>
					</section>
				</BackendWrap>
			</PageProvider>
		</ThemeProvider>
	);
}

