
import { css } from '@emotion/css';
import { locateSubDocument } from 'Common/ViewUtils';
import { theme } from 'Shared/backend/ThemeProvider';
import { Checkbox, Text } from 'Shared/forms/Inputs';
import { LabelOnRightWrap, Wrap } from 'Shared/forms/Wrap';
import { Match, Show, Switch } from 'solid-js';


const testButtonStyle = () => css({
	display: 'flex',
	alignItems: 'center',
	gap: '0.5em'
});

export function AddButton(props)
{
	const item = () => locateSubDocument(props.store,props.location);

	//XXX sanity check if google doc url doesn't contain /edit?usp=sharing we can't convert it to a PDF

	//XXX is this file a duplicate? Maybe in part?

	const googleDocPDFConvert = () => item().link?.includes('google.com/document/') && item().link?.includes('/edit?usp=sharing');

	const googleLink = () => item().gDriveDownloadAsPDF ? item().link.replace('/edit?usp=sharing','/export?format=pdf') : item().link;

	return (<>
		<Wrap label='Button Link'>
			<Text {...props} field='link' placeholder='eg https:webaddress | page title for your own site eg contact | google doc share link for download'/>
		</Wrap>

		<Switch>
			<Match when={googleDocPDFConvert()}>
				<LabelOnRightWrap label='Convert Google Doc to a PDF when users download it'>
					<Checkbox {...props} field='gDriveDownloadAsPDF' />
				</LabelOnRightWrap>
			</Match>
			<Match when={true}>
				<LabelOnRightWrap label='Open link on the same window'>
					<Checkbox {...props} field='openInSameWindow' />
				</LabelOnRightWrap>
			</Match>
		</Switch>

		<Wrap label='Button Text'>
			<Text {...props} field='linkText' placeholder='More Info' />
		</Wrap>

		<Show when={googleDocPDFConvert()}>
			<div class={testButtonStyle()}> 
				<h4 class={css(theme().section.heading)}>Test your button:</h4>

				<a href={googleLink()} target='_blank'>
					<button class={css(theme().button)} >
						{props.linkText ?? 'More Info'}
					</button>
				</a>
			</div>
		</Show>
	</>);
}

