import {DocumentQueries} from 'Common/config/PageConfigTypes';
import {home as homeModel} from 'Shared/model/Home';
import { z } from 'zod';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData, backendParams} from 'Common/BackendPageConfig';
import { HomePage } from 'Shared/backend/homePage/HomePage';



export const homeParams = backendParams.extend({
	tab: z.string()
}).strict();	
export type HomeParams = z.infer<typeof homeParams>;


export const homeData = backendData.extend({
	params: homeParams,
	home: homeModel,
//	events: z.array(eventModel),

	/* Component data types: */

}).strict();
export type HomeData = z.infer<typeof homeData>;



export class Home extends BackendPageConfig<HomeData,HomeParams>
{
	static readonly pageName = 'backend/home';
	name() { return Home.pageName; }

	access() { return 'venueFullEditor' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			template: HomePage
		};
	}

	permissions()
	{
		return ({
			edit: { collection:'home' },
			image: { collection:'home', assetFolder:'Showcase-image'},
			carouselImage: { collection:'home', assetFolder:'Home-showcaseCarouselImage'},
		});
	}

	documents(params:HomeParams): DocumentQueries 
	{
		return ({
			...super.documents(params),
			home: {
				type: 'object',
				collection: 'home'
			},
		});
	}
}



