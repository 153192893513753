import { css } from '@emotion/css';
import { VenueUrls } from 'Common/VenueUrls';
import { theme } from 'Shared/backend/ThemeProvider';
import { AddButton } from 'Shared/backend/widgets/AddButton';
import { indentPaneStyle } from 'Shared/backend/widgets/CarouselWidget';
import { verticalStyle } from 'Shared/backend/widgets/Widget';
import { Checkbox, Html, Text } from 'Shared/forms/Inputs'
import { LabelOnRightWrap, Wrap } from 'Shared/forms/Wrap'
import { usePage } from 'Shared/frontend/PageProvider';
import { createSignal, Show } from 'solid-js'
import { Match, Switch } from 'solid-js'

export const showHideStyle = () => css(theme().a, {
	fontWeight: 'bold',
	color: 'black',

	':visited': {
		color: 'black',
	}
});


export function WidgetInfo(props)
{
	const urls = new VenueUrls(usePage().build,usePage().site.key);

	const [showDetails,setShowDetails] = createSignal(props.details!=null);

//TODO See CarouselWidget - probably reuse there too...

	const page = usePage();

	return (
		<div class={verticalStyle()}>
			<Wrap label='Teaser'>
				<Text {...props} field='teaser'/>
			</Wrap>

			<Wrap label='Title'>
				<Text {...props} field='title'/>
			</Wrap>

			<Switch>
				<Match when={page.name()=='backend/hire'}>
					<Wrap label='Location/Area/Tagline'>
						<Text {...props} field='area'/>
					</Wrap>

					<Wrap label='Capacity'>
						<Text {...props} field='capacity'/>
					</Wrap>

					<Wrap label='Rate'>
						<Text {...props} field='rate'/>
					</Wrap>
				</Match>
				<Match when={true}>
					<Wrap label='Tagline'>
						<Text {...props} field='tagline'/>
					</Wrap>
				</Match>
			</Switch>

			<Wrap label='Details' 
				notes={props.layout == 'slideshow' ? 'Keep details short so they fit over the image on mobile' : null}
			>
				<Html {...props} urls={urls} field='details' options={{
					height: 200,
					menubar: false,
					plugins: ['link'],
					toolbar: 'removeformat | undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | link ',
					statusbar: true,
					resize: true
				}} />
			</Wrap>

			<LabelOnRightWrap label='Add Button'>
				<Checkbox {...props} field='addLink' />
			</LabelOnRightWrap>

			<Show when={props.addLink || props.layout == 'button'}>
				<div class={indentPaneStyle()}>
					<AddButton {...props} />
				</div>
			</Show>
		</div>
	);
}	

