import { Show, Switch,Match } from 'solid-js'

export function ShowcaseItemsTitle(props)
{
	return (
		<Switch>
			<Match when={props.title}>
				{props.title}
			</Match>
			<Match when={props.teaser || props.tagline || props.imageCaption}>
				{props.teaser} {props.tagline} {props.imageCaption}
			</Match>
			<Match when={props.layout=='buttons'}>
				Button
				<Show when={props.linkText}>
					{' • ' + props.linkText}
				</Show>
			</Match>
			<Match when={true}>
				Image  
			</Match>
		</Switch>
	);
}
