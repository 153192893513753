import { ISet, verticalStyle } from 'Shared/backend/eventPage/artist/ArtistForm';
import { IconColour } from 'Shared/backend/IconColour';
import { LinkOpen } from 'Shared/backend/icons/LinkOpen';
import { PeopleCircle } from 'Shared/backend/icons/PeopleCircle';
import { PersonCircle } from 'Shared/backend/icons/PersonCircle';
import { Match, Show, Switch } from 'solid-js';
import { ImageUpload } from 'Shared/forms/ImageUpload';
import { usePage } from 'Shared/frontend/PageProvider';
import { locateSubDocument } from 'Common/ViewUtils';
import { VenueUrls } from 'Common/VenueUrls';
import { createSignal } from 'solid-js';
import { css, cx } from '@emotion/css';
import { inlineOpenLinkStyle, palette } from 'Shared/backend/Theme';
import { reconcile } from 'solid-js/store';
import { IconPane } from 'Shared/forms/IconPane';
import { HeadingWithNote } from 'Shared/backend/eventPage/artist/HeadingWithNote';
import { theme } from 'Shared/backend/ThemeProvider';

const imageStyle = () => css({
	img: {
		maxWidth: 450,
		maxHeight: 300
	},

	'button[disabled]': {
		backgroundColor: '#7e94a5 !important',
		borderColor: '#7e94a5 !important'  
	}
});

const activeStyle = () => css({
	color: palette.active
});


export function PressShot(props:ISet)
{
	const page = usePage();
	const urls = new VenueUrls(page.build,page.site.key);

	const setImage = value => props.setStore(...props.location,'image',reconcile(value));
	const image = () => locateSubDocument(props.store,[...props.location,'image']);

	const [buttonNode,setButtonNode] = createSignal();

	return (
		<section>
			<HeadingWithNote
				heading='Press shot'
				note='VOS will crop image to 3:2 landscape ratio'
			/>

			<IconPane>
				<Switch>
					<Match when={props.performanceType=='originalSolo' || props.performanceType=='coversSolo' || props.performanceType=='dj' }>
						<PersonCircle fill={props.image?.hash ? IconColour.active : IconColour.alert} />
					</Match>
					<Match when={true}>
						<PeopleCircle fill={props.image?.hash ? IconColour.active : IconColour.alert} />
					</Match>
				</Switch>

				<div class={verticalStyle()}>
					<div class='search'>
						<Show when={props.facebook || props.website}>
					
							<label>Search for photos on Artists
								<Show when={props.facebook}>
									<a class={css(theme().a)} href={`https://www.facebook.com/${props.facebook}/photos`} target='_blank'>
										<span class={cx(inlineOpenLinkStyle(),activeStyle())}>
											FACEBOOK
											<LinkOpen class={css(theme().linkIcon)} fill={IconColour.active}/>
										</span>
									</a>
								</Show>

								<Show when={props.facebook && props.website}>
									or 
								</Show>

								<Show when={props.website}>
									<a class={css(theme().a)} href={props.website} target='_blank'>
										<span class={cx(inlineOpenLinkStyle(),activeStyle())}>
											WEBSITE
											<LinkOpen class={css(theme().linkIcon)} fill={IconColour.active}/>
										</span>
									</a>
								</Show>
							</label>
						</Show>
					</div>

					<div class={imageStyle()}>
						<ImageUpload
							{...props}
							location={[...props.location,'image']} 
							permission='lineupImage' 
							image={image()} 
							setImage={setImage}
							page={page} 
							dimensions='landscape'
							previewSize='medium'
							urls={urls}
							fallbackUrl='defaults/profile3x2.png'
							node={buttonNode}
							setNode={setButtonNode}
						/>
					</div>
				</div>
			</IconPane>
		</section>
	);
}

