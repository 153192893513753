import { css } from '@emotion/css';
import { VenueUrls } from 'Common/VenueUrls';
import { ImageUpload } from 'Shared/forms/ImageUpload';
import { Text } from 'Shared/forms/Inputs'
import { Wrap } from 'Shared/forms/Wrap'
import { usePage } from 'Shared/frontend/PageProvider';
import { reconcile } from 'solid-js/store';

const paneStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	gap: '0.8em',
	marginTop: '1em'
});

const imageUploadStyle = () => css({});


export function ParallaxWidget(props)
{
	const page = usePage();
	const setImage = value => props.setStore(...props.location,'image',reconcile(value));
	const setLogo = value => props.setStore(...props.location,'logo',reconcile(value));

	return (
		<div class={paneStyle()}>
			<Wrap label='Image (landscape ratio 3:2)'>
				<div class={imageUploadStyle()}>
					<ImageUpload 
						docId={props.docId}
						small={true}
						location={[...props.location,'image']}
						image={props.image} 
						setImage={setImage}
						urls={new VenueUrls(page.build,page.site.key)}
						dimensions='landscape'
						permission='image'
						fallbackUrl='defaults/profile3x2.png'
						previewSize='full'
						page={page}
					/>
				</div>
			</Wrap>

			<Wrap label='Overlayed logo (landscape ratio 3:2) (optional)'>
				<div class={imageUploadStyle()}>
					<ImageUpload 
						docId={props.docId}
						small={true}
						location={[...props.location,'logo']}
						image={props.logo} 
						setImage={setLogo}
						urls={new VenueUrls(page.build,page.site.key)}
						dimensions='landscape'
						permission='image'
						fallbackUrl='defaults/profile3x2.png'
						previewSize='full'
						page={page}
					/>
				</div>
			</Wrap>

			<Wrap label='Logo offset (optional)'>
				<Text {...props} field='logoOffset' placeholder='Eg -50px'/>
			</Wrap>

			<Wrap label='Overlayed text (optional)'>
				<Text {...props} field='text'/>
			</Wrap>

			{/* TODO if present, check is a number < 1 and > 0 */}
			<Wrap label='Scroll rate (optional)' instructionsAfterLabel='Value must be between 0 and 1'>
				<Text {...props} field='scrollRate' />
			</Wrap>
		</div>
	);
}

