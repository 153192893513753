import { css } from '@emotion/css';
import { VenueUrls } from 'Common/VenueUrls';
import { verticalStyle } from 'Shared/backend/widgets/Widget';
import { Checkbox, Html, Integer, Text } from 'Shared/forms/Inputs'
import { LabelOnLeftWrap, LabelOnRightWrap, Wrap } from 'Shared/forms/Wrap'
import { usePage } from 'Shared/frontend/PageProvider';
import { createSignal, Show } from 'solid-js'
import { showHideStyle } from 'Shared/backend/widgets/WidgetInfo';

const verticalWithTopMarginStyle = () => css(verticalStyle(),{
	marginTop: '1em'
});

export const indentPaneStyle = () => css(verticalStyle(), {
	paddingLeft: '1em'
});

export function CarouselWidget(props)
{
	const page = usePage();
	const urls = new VenueUrls(page.build,page.site.key);

	const [showExtra,setShowExtra] = createSignal(props.details!=null || props.teaser!=null || props.tagline!=null || props.addLink);

//XXX maybe combine with WidgetInfo.tsx?	
	return (
		<div class={verticalWithTopMarginStyle()}>
			<Text {...props} field='title' placeholder='Title'/>

			<LabelOnLeftWrap label='Flip to horizontal scrolling list if events exceed:'>
				<Integer {...props} field='carouselToScroll'/>
			</LabelOnLeftWrap>

			<Show when={props.layout?.includes('carousel')}>
				<LabelOnLeftWrap label='Make carousel extra wide on big screens'
					notes='Displays more posters in a row but might not look as neat because it will be wider than other sections'
				>
					<Checkbox {...props} field='noSectionWidth' />
				</LabelOnLeftWrap>
			</Show>

			<a href='' class={showHideStyle()} onClick={() =>setShowExtra(!showExtra())}>
				<i class={`fas ${showExtra() ? 'fa-caret-down' : 'fa-caret-right'}`}/>
				{' '}Extra info above carousel?
			</a>

			<Show when={showExtra()}>
				<div class={indentPaneStyle()}>
					<Wrap label='Teaser (appears above title)'>
						<Text {...props} field='teaser' placeholder='Optional'/>
					</Wrap>

					<Wrap label='Tagline (appears below title)'>
						<Text {...props} field='tagline' placeholder='Optional' />
					</Wrap>

					<Wrap label='Details'>
						<Html {...props} field='details' urls={urls} placeholder='Optional'  options={{
							height: 200,
							menubar: false,
							plugins: ['link'],
							toolbar: 'removeformat | undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | link ',
							statusbar: true,
							resize: true
						}}/>
					</Wrap>

{/* XXX maybe use AddButton.tsx here?*/}

					<LabelOnRightWrap label='Add button'>
						<Checkbox {...props} field='addLink' />
					</LabelOnRightWrap>

					<Show when={props.addLink}>
						<div class={indentPaneStyle()}>
							<Wrap label='Button Text'>
								<Text {...props} field='linkText' placeholder='More Info'/>
							</Wrap>
							<Wrap label='Link to webpage'>
								<Text {...props} field='link' placeholder='https://webaddress or page title for your own site'/>
							</Wrap>
							<LabelOnRightWrap label='Open link on the same window'>
								<Checkbox {...props} field='openInSameWindow' />
							</LabelOnRightWrap>
						</div>
					</Show>
				</div>
			</Show>
		</div>
	);
}

