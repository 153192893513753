import { css } from '@emotion/css';
import { IEventProps } from 'Shared/backend/eventPage/EventPage'
import { theme } from 'Shared/backend/ThemeProvider';
import { SingleSelect } from 'Shared/forms/Inputs'
import { Wrap } from 'Shared/forms/Wrap';
import { Match,  Switch } from 'solid-js'

export function Type(eventProps:IEventProps)
{
	return (
		<Switch>
			<Match when={! eventProps.store.eventType}>
				<Wrap label='Start by choosing your event type...'> 
					<SingleSelect {...eventProps} field='eventType' options={{
							music:'Music Event',
							social:'Social Event',
							tv:'Televised Event'
						}} 
					/>
				</Wrap>
			</Match>
			<Match when={true}>
				<div class={css(theme().inverseHeading)}>
					<SingleSelect {...eventProps} field='eventType' options={{
							music:'Music Event',
							social:'Social Event',
							tv:'Televised Event'
						}} 
					/>
				</div>
			</Match>
		</Switch>
	);
}
