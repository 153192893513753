import { css } from '@emotion/css';
import { SingleSelect } from 'Shared/forms/Inputs';

export const inverseHeadingStyle = () => css({
	display: 'flex',
	alignItems: 'center',
	padding: '0.1em 0.5em',
	fontSize: '1.1em',
	background: '#1b2126',

	label: {
		color: 'white',
		fontWeight: 'bold'
	},

	select: {
		border: 'none',
		color: 'white !important',
		fontSize: '1.0em'
	}
});

export function HomeSelector(props)
{
	//TODO ben once parallaxBanner added to showcase widgets add this option below venuePhotoAndLogo: 'Venue Photo with Logo Overlayed',

	return (
		<div class={inverseHeadingStyle()}>
			<label>Layout:</label>

			<SingleSelect {...props} field='layout' required={true} placeholder='SELECT A SECTION LAYOUT' options={{
				buttons: 'Button Row',
				noImage:'Info with no image',
				slideshow:'Image(s) with optional info overlayed',
				ss_imageRight:'Image(s) to right of Info on widescreens',
				ss_imageLeft:'Image(s) to left of Info on widescreens',
				ss_imageAbove:'Heading(s), Image(s) then Details',
				ss_imageBelow:'Image(s) then Info',
				carouselAllEvents:'Carousel of All events',
				carouselMusic:'Carousel of Music events',
				carouselNonMusic:'Carousel of Non-music events',
				carouselSpecials:'Carousel of All Specials',
				carouselSpecials_group1:'Carousel of Specials Group 1',
				carouselSpecials_group2:'Carousel of Specials Group 2',
				carouselSpecials_group3:'Carousel of Specials Group 3',
				parallax:'Parallax Image',
				popup:'Popup'
			}} />
		</div>
	);
}
