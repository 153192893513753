import { css } from '@emotion/css';
import { VenueUrls } from 'Common/VenueUrls';
import { locateSubDocument } from 'Common/ViewUtils';
import { paneStyle } from 'Shared/backend/widgets/Widget';
import { ImageUpload } from 'Shared/forms/ImageUpload';
import { Text } from 'Shared/forms/Inputs'
import { Wrap } from 'Shared/forms/Wrap'
import { usePage } from 'Shared/frontend/PageProvider';
import { reconcile } from 'solid-js/store';

const imageUploadStyle = () => css({
	img: {
		maxWidth: 450,
		maxHeight: 300
	}
});

export function ImagesWithSharedInfoWidget(props)
{
	const page = usePage();
	const setImage = value => props.setStore(...props.location,'image',reconcile(value));
	const image = () => locateSubDocument(props.store,[...props.location,'image']) ?? null;

	return (
		<div class={paneStyle()}>
			<Wrap label='Image (landscape ratio 3:2)'>
				<div class={imageUploadStyle()}>
					<ImageUpload 
						docId={props.docId}
						location={[...props.location,'image']}
						image={image()} 
						setImage={setImage}
						urls={new VenueUrls(page.build,page.site.key)}
						dimensions='landscape'
						permission='carouselImage'
						fallbackUrl='defaults/profile3x2.png'
						previewSize='full'
						page={page}
					/>
				</div>
			</Wrap>

			<Wrap label='Image Caption' notes='Displayed on image'>
				<Text {...props} field='imageCaption' />
			</Wrap>
		</div>
	);
}
