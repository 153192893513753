import { css } from '@emotion/css';
import { ImagesWidget } from 'Shared/backend/widgets/ImagesWidget';
import { ShowcaseItemsTitle } from 'Shared/backend/widgets/ShowcaseItemsTitle';
import { ImagesWithSharedInfoWidget } from 'Shared/backend/widgets/ImagesWithSharedInfoWidget';
import { theme } from 'Shared/backend/ThemeProvider';
import { addItemAndOpen, StoreRepeater } from 'Shared/forms/StoreRepeater';
import { usePage } from 'Shared/frontend/PageProvider';
import { Match, Switch } from 'solid-js'


export function ImagesRepeater(props)
{
	const page = usePage();
	let repeaterNode;

	const addItemLabel = () => {
		if (props.layout == 'buttons') 
			return 'Add button';

		if ((props.items ?? []).length==0) 
			return props.sameInfo ? 'Add Image': 'Add content';

		if (props.items.length == 1) 
			return props.sameInfo ?
				'Add more images to convert to a slideshow' : 
				'Add more content blocks to convert to a slideshow';

		return props.sameInfo ?
			'Add more images to your slideshow' :
			'Add more content blocks to your slideshow';
	};

	return (
		<div>
			<Switch>
				<Match when={props.sameInfo}>
					<StoreRepeater {...props} 
						ref={repeaterNode} 
						page={page} 
						permission='edit' 
						location={props.location} 
						field='items' 
						renderTitle={i => <ShowcaseItemsTitle {...i()} layout={props.layout} />} 
					>
						{(item,index) => <ImagesWithSharedInfoWidget {...props} location={[...props.location,'items',index]} index={index} />}
					</StoreRepeater>

					<button class={css(theme().button)}
						onClick={() => addItemAndOpen({...props,permission:'edit',field:'items'},repeaterNode,{})}
					>
						<i class='fas fa-plus'/> {addItemLabel()}
					</button>
				</Match>
				<Match when={true}>
					<StoreRepeater {...props} 
						ref={repeaterNode} 
						page={page} 
						permission='edit' 
						location={props.location} 
						field='items' 
						renderTitle={i => <ShowcaseItemsTitle {...i()} layout={props.layout} />} 
					>
						{(item,index) => 
							<ImagesWidget {...props} location={[...props.location,'items',index]} index={index} />
						}
					</StoreRepeater>

					<button class={css(theme().button)}
						onClick={() => addItemAndOpen({...props,permission:'edit',field:'items'},repeaterNode,{})}
					>
						<i class='fas fa-plus'/> {addItemLabel()}
					</button>
				</Match>
			</Switch>
		</div>
	);
}

