import {VenueRoutes as SharedRoutes, VenueRoutesType} from 'Shared/routes/VenueRoutes';
import {Home as BackendHome} from 'Shared/view/backend/Home'
import { Menu as FrontendMenu } from 'Shared/view/frontend/Menu';


export class DifferentDrummerRoutes extends SharedRoutes
{
	frontend()
	{

		return <VenueRoutesType> ({
			...super.frontend(),

//			'/': [frontendHomePage(this.settings.sharedPages.home), () => ({})],		

			'/food':	[FrontendMenu,() => ({slug:'food'})],
			'/drinks':	[FrontendMenu,() => ({slug:'drinks'})],
		});
	}

	backend()
	{
		return <VenueRoutesType>({
			...super.backend(),

//			'/admin': 				[BackendVenue, ()=>({})],
			'/admin/home': 			[BackendHome, ()=>({})],
			'/admin/home/:tab': 	[BackendHome, params=>({tab:params.tab})],
		});
	}
}
